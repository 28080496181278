/* Modal */

.modal {
    display: block;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: #fff;
    padding: 2rem;
    border-radius: 0.5rem;
    max-width: 60rem;
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.2);
    position: relative;
}

.modal-content h4 {
    font-weight: bold;
    font-size: 14px;
}

.close {
    color: #aaa;
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #edeff1;
    height: 3rem;
    width: 3rem;
    display: grid;
    place-content: center;
    border-radius: 50%;
    background-color: #5865f2;
    cursor: pointer;
}

.modal .bar {
    height: 0.1rem;
    background: #edeff1;
    border-radius: 1rem;
    width: 100%;
    margin: 1rem 0;
}

.modal .post {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.modal .post-header {
    display: flex;
    align-items: center;
}

.modal .profile-img {
    height: 4rem;
    min-height: 4rem;
    width: 4rem;
    min-width: 4rem;
    margin-right: 0.5rem;
}

.modal .profile-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 50%;
}

.modal .profile-name {
    font-weight: bold;
}

.modal .stats {
    font-size: 1rem;
    margin-left: auto;
    color: #454748;
}

.modal .comment {
    font-size: 1.2rem;
    margin-left: 1rem;
}

.modal .post-body {
    padding-left: 4.5rem;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
}

.modal .post-body .delete {
    margin-left: auto;
    height: 3rem;
    width: 3rem;
    min-width: 3rem;
    min-height: 3rem;
    border-radius: 50%;
    display: grid;
    place-content: center;
    background: #be1431;
    color: #f6f7f8;
    font-size: 1.4rem;
    cursor: pointer;
}

.inbox-modal {
    height: 40rem;
    overflow-y: auto;
    padding-right: 2rem;
}

.signup-form {
    width: 100%;
}

.membership-color {
    background: #ffb02e !important;
}

.signup-form form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.signup-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* Adjusted this to space-between for the desired layout */
    width: 100%;
}

.signup-footer p {
    font-size: 1.2rem;
    cursor: pointer;
    color: #222;
    flex-grow: 0;
    /* Ensure it doesn't expand */
    margin-right: auto;
    /* Push the subsequent content to the right */
}

.btn.btn-1 {
    margin-left: auto;
    /* Pushes the button to the right */
}

.signup-form span {
    font-size: 1.2rem;
    margin-left: auto;
    background: #028e51;
    padding: 0.8rem 1.2rem;
    color: #fff;
    font-weight: bold;
    border-radius: 0.3rem;
}

.f-control {
    display: flex;
    gap: 1rem;
    width: 100%;
    position: relative;
    margin: 1rem 0;
}

.f-control.select-control {
    display: flex;
    align-items: center;
}

.f-control.select-control label {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
}

.f-control.select-control select {
    padding: 0.8rem 1rem;
    border: 0.1rem solid #edeff1;
    border-radius: 1rem;
    font-size: 1.2rem;
    background: #f6f7f8;
}

.f-control .border {
    position: absolute;
    width: 0%;
    height: 0.1rem;
    bottom: 0;
    background: #5865f2;
    transition: 1s ease;
}

.f-control label {
    position: absolute;
    top: 2rem;
    left: 1rem;
    transform: translateY(-50%);
}

.f-control input {
    width: 100%;
    padding: 1rem 2rem;
    border-bottom: 0.1rem solid #ccc;
    border-radius: 0.2rem;
    padding-left: 3.5rem;
    font-size: 1.2rem;
}

.f-control textarea {
    width: 100%;
    height: 10rem;
    resize: none;
    padding: 1rem 2rem;
    border-bottom: 0.1rem solid #ccc;
    border-radius: 0.2rem;
    padding-left: 3.5rem;
    font-size: 1.2rem;
}

.f-control input:focus+.border {
    width: 100%;
}

.f-control textarea:focus+.border {
    width: 100%;
}

.edit-profile-form p {
    font-size: 1.2rem;
    color: #454748;
    margin-top: 1rem;
}


/* Modal */

@media (max-width: 650px) {
    .modal-content {
        width: 80vw!important;
    }
}

#usernameNav {
    font-weight: bolder;
}

.f-control.select-control select {
    flex-grow: 1;
    /* Takes up remaining available space */
    background-color: #f6f7f8;
    border: 0.1rem solid #edeff1;
    border-radius: 0.2rem;
    padding: 1rem;
    margin-left: 1rem;
    /* Provides space between the label and the select box */
    cursor: pointer;
    /* Cursor changes to a hand when hovered over */
}

.f-control.select-control select:focus {
    border-color: #5865f2;
}

.f-control.select-control select:hover {
    background-color: #edeff1;
}