/* About */

.about .logo {
    display: inline;
}

.about .logo span {
    margin: 0 .15rem 0 .3rem;
}

.about h3 {
    font-weight: bold;
    font-size: 16.5px;
    margin-top: 3px;
}

.about p {
    font-size: 1.2rem;
    margin-top: 1.2rem;
}

.about .about-alert {
    background: #be1431;
    color: #fff !important;
}


/* About */


/* Table */

table {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    margin-top: 1rem;
    font-size: 1.3rem;
    overflow-x: hidden;
}

th,
td {
    border: 0.1rem solid #edeff1;
    padding: 1.5rem;
}

th {
    background-color: #f6f7f8;
    font-weight: 500;
    color: #5865f2;
}

tr:nth-child(odd) {
    background-color: #f6f7f8;
}

tr:hover {
    background-color: #edeff1;
}

.highlight-row {
    background-color: lightgreen !important;
  }

  .user-count {
    text-align: center;
    font-weight: lighter !important;
  }
   .user-count span {
    font-weight: bolder !important;
    color: #5865f2;
  }
/* Table */

.about_img {
    max-width: 5em;
}