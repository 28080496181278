main {
    margin: 5rem 0;
}

.create-post {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    background: #fff;
    gap: 1rem;
    border: 0.1rem solid #ccc;
    border-radius: 0.5rem;
}

.create-post .profile {
    margin: 0;
}

.create-post .post-input {
    width: calc(100% - 15rem);
    margin: 0 auto;
}

.post-input input {
    width: 100%;
    padding: 1rem;
    background: #f6f7f8;
    border: 0.1rem solid #edeff1;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    cursor: pointer;
}

.create-post .post-type {
    height: 3.8rem;
    width: 3.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    border-radius: 50%;
    background: #f6f7f8;
    cursor: pointer;
    color: #222;
}

.post-type:hover {
    color: #5865f2;
}

.profile {
    height: 3.8rem;
    width: 3.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    border-radius: 50%;
    background: #5865f2;
    cursor: pointer;
    margin-left: 1rem;
}

.home-sort-posts {
    width: 25%;
    display: none;
}

.home-sort-posts select {
    font-size: 14px!important;
}


/* Membership Card */

.membership {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.membership .membership-icon {
    height: 3.8rem;
    width: 3.8rem;
    min-height: 3.8rem;
    min-width: 3.8rem;
    display: grid;
    place-content: center;
    border-radius: 50%;
    background: #ffb02e;
    color: #222;
    border: 0.1rem solid #222;
}

.membership-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    gap: 0.5rem;
}

.membership-content h4 {
    font-weight: bold;
    font-size: 14px;
}

.membership-content span {
    font-size: 1.2rem;
    color: #454748;
}

.membership-content button:hover {
    color: #fff;
}


/* Membership Card */


/* Base Card */

.base-card {
    width: 100%;
    padding: 1rem;
    background: #fff;
    border: 0.1rem solid #ccc;
    border-radius: 0.5rem;
}

.base-card h3 {
    font-weight: bold;
    font-size: 16.2px;
    margin: 0.5rem 0;
}

.base-card ol {
    list-style-position: inside;
    font-size: 1.2rem;
    margin: 1rem 0;
}


/* Base Card */