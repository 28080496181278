header {
    background: #fff;
    height: auto;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
}

.banner-profile {
    width: 100%;
    height: auto;
    margin-top: 5rem;
    position: relative;
}

.profile-container {
    background: #fff;
    height: 8rem;
}

.profile-info {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.profile-info .profile-name {
    font-size: 2.2rem;
    font-weight: bold;
    position: relative;
}

.profile-container .profile-img {
    height: 6rem;
    width: 6rem;
    min-width: 6rem;
    min-height: 6rem;
    border-radius: 50%;
    outline: 1rem solid #fff;
    position: relative;
}

.profile-container .profile-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.banner-profile .profile-banner {
    width: 100%;
    height: 13rem;
    overflow: hidden;
    position: relative;
}

.profile-banner .btn-3 {
    position: absolute;
    bottom: 1rem;
    right: 5rem;
}

.banner-profile .profile-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-info .profile-img .btn-3 {
    position: absolute;
    top: 0;
    right: 0;
}

.profile-info .profile-name .btn-3 {
    position: absolute;
    top: 0;
    right: -3rem;
}

header {
    height: 5rem;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    padding-right: calc(var(--bs-gutter-x) * .5)!important;
    padding-left: calc(var(--bs-gutter-x) * .5)!important;
}

nav {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
    padding: 0.5rem 0;
}

nav .profile {
    position: relative;
}

.avatar {
    pointer-events: none;
}

.dropdown {
    width: 20rem;
    position: absolute;
    background-color: #fff;
    top: 100%;
    left: -150%;
    transform: translateX(-50%);
    z-index: 10;
    border: 0.1rem solid #ccc;
    border-radius: 0.4rem;
    font-size: 1.2rem;
}

.dropdown .arrow-top {
    position: absolute;
    content: "";
    height: 2rem;
    width: 2rem;
    background: #fff;
    top: 0;
    left: 88%;
    z-index: -1;
    transform: rotate(45deg) translateX(-50%);
    border: 0.1rem solid #ccc;
}

.dropdown-content {
    width: 100%;
    padding: 1rem;
    background: #fff;
}

.dropdown ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dropdown li {
    margin-bottom: 0.5rem;
    padding: 0.5rem 2rem;
}

.dropdown a {
    text-decoration: none;
    color: #222;
}

.dropdown a:hover,
.dropdown li:hover {
    color: #5865f2;
}


/* Logo */

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #222;
    cursor: pointer;
}

.logo:hover {
    color: #222;
}

.logo span {
    font-size: 2rem;
    font-weight: bolder;
    color: #5865f2;
    margin-right: 0.1rem;
}


/* Search */

.search-bar {
    width: 60%;
    max-width: 60vw;
    position: relative;
    height: 3.8rem;
    margin-left: auto;
}

.search-bar input {
    width: 100%;
    height: 100%;
    padding: 0.5rem 0;
    padding-left: 4.5rem;
    border-radius: 6rem;
    background: #f6f7f8;
    border: 0.1rem solid #edeff1;
    font-size: 1.2rem;
}

.search-bar .search-icon {
    position: absolute;
    top: 50%;
    left: 2rem;
    transform: translateY(-50%);
    font-size: 1.6rem;
    color: #878a8c;
}


/* Inbox */

.inbox {
    height: 3.8rem;
    width: 3.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    border-radius: 50%;
    background: #f6f7f8;
    cursor: pointer;
    color: #222;
    margin-left: auto;
}

.inbox:hover {
    color: #5865f2;
}


/* Profile */

.profile {
    height: 3.8rem;
    width: 3.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    border-radius: 50%;
    background: #5865f2;
    cursor: pointer;
    margin-left: 1rem;
}

.post-type-text,
.post-type-link {
    margin: 0 0 0 10px;
    height: 3.8rem;
    width: 3.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    border-radius: 50%;
    background: #f6f7f8;
    cursor: pointer;
    color: #222;
}

.post-type-link {
    margin-left: auto;
}

.logo_img {
    max-width: 2.5em;
}