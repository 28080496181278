/* Payment Modes */

.payment-modes {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding-top: 1rem;
}

.payment-mode {
    height: 7rem;
    min-height: 5rem;
    width: 10rem;
    min-width: 5rem;
    border: 0.1rem solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    border-radius: 0.5rem;
}

.payment-mode.scale-2 img {
    transform: scale(1.2);
}

.payment-mode img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.f-control.select-control.flex-column label {
    font-size: 1.2rem;
}


/* Payment Modes */


/* Tabs */

.tabs {
    display: flex;
    justify-content: flex-start;
    margin-top: 2rem;
}

.tab-button {
    background-color: #fff;
    border: none;
    padding: 1rem 2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1.2rem;
    border-top: 0.1rem solid #ccc;
    border-left: none;
    border-right: none;
}

.tab-button:nth-child(1) {
    border-top-left-radius: 0.5rem;
    border: none;
    border-left: 0.1rem solid #ccc;
    border-top: 0.1rem solid #ccc;
}

.tab-button:last-child {
    border-top-right-radius: 0.5rem;
    border-right: 0.1rem solid #ccc;
}

.tab-button.active {
    background-color: #eff0f0;
}

.tab-content {
    display: none;
    padding: 2rem;
    border-top: none;
    border: none;
    border-radius: 0;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-left: 0.1rem solid #ccc;
    border-bottom: 0.1rem solid #ccc;
    border-right: 0.1rem solid #ccc;
}

.tab-content.active {
    display: block;
}


/* Tabs */