.close-post {
    background: #fff;
    height: auto;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
}

.close-post {
    z-index: 99;
}

.close-post {
    top: 5rem;
    background: #5865f2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
}

.close-post-details {
    display: flex;
    align-items: center;
    height: 100%;
    color: #fff;
}

.close-post-details .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
    height: 3.8rem;
    border-radius: 1rem;
    background: #f6f7f821;
    border: 0.1rem solid #edeff1;
    cursor: pointer;
    margin-right: 1rem;
    color: #fff;
    text-decoration: none;
}

.close-post-details h4 {
    margin: 0 auto;
    font-size: 14px;
    font-weight: bold;
}


/* Feed Post */

.feed-post .post-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.2rem;
}

.feed-post .post-header .profile-img {
    height: 2rem;
    min-height: 2rem;
    width: 2rem;
    min-width: 2rem;
    margin-right: 0.5rem;
}

.feed-post .post-header .profile-img img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.feed-post .post-header .profile-name {
    font-weight: bold;
}

.feed-post .post-header .stats {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    color: #454748;
}

.feed-post .post-body {
    font-size: 1.3rem;
    color: #454748;
}

.feed-post .post-body h4 {
    font-size: 2.5rem;
    margin: 1.2rem 0;
    margin-bottom: 0.5rem;
    color: #222;
    font-weight: normal !important;
}

.feed-post .post-body a {
    color: #136ac8;
    font-size: 1.2rem;
}

.profile-comment .post-body a p {
    color: #454748;
}

.profile-comment .post-body a p::before {
    content: "\f10d";
    font-family: "Font Awesome 5 Pro";
    font-size: 3rem;
    font-weight: 900;
    margin-right: 1rem;
    opacity: 0.2;
}

.feed-post .post-footer {
    display: flex;
    width: 100%;
    gap: 1rem;
    font-size: 1.2rem;
    margin-top: 2rem;
    padding: 1rem;
}

.post-footer .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    background: #f6f7f8;
    border: 0.1rem solid #edeff1;
    cursor: pointer;
}

.post-footer .icons:hover {
    color: #5865f2;
}

.post-footer .icons i {
    font-size: 2rem;
}


/* Post Detail Page */

.post-self-comment {
    margin: 1rem 0;
    width: 100%;
}

.post-self-comment p {
    font-size: 1.2rem;
}

.post-self-comment p span {
    color: #5865f2;
}

.post-self-comment .comment-box {
    width: 100%;
}

.post-self-comment .comment-box textarea {
    height: 10rem;
    width: 100%;
    border: 0.1rem solid #ccc;
    font-size: 1.2rem;
    margin-top: 1rem;
    border-radius: 0.2rem;
    padding: 0.5rem 1rem;
    resize: none;
}

.post-other-comments .comment-top {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    padding-left: 1rem;
}

.comment-top .sort-by {
    color: #be1431;
    font-weight: bold;
}

.comment-top .search-comment {
    margin-left: 2rem;
    width: 50%;
    position: relative;
}

.comment-top .search-comment input {
    border: 0.1rem solid #ccc;
    padding: 0.8rem 1.2rem;
    font-size: 1.2rem;
    border-radius: 0.5rem;
    padding-left: 2.5rem;
}

.comment-top .search-comment .search-icon {
    position: absolute;
    pointer-events: none;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    color: #878a8c;
}

.comment-set .reply {
    padding: 2rem;
}

.feed-post-details .comment .comment-header {
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.feed-post-details .comment .profile-img {
    height: 4rem;
    min-height: 4rem;
    width: 4rem;
    min-width: 4rem;
    margin-right: 0.5rem;
    border-radius: 50%;
    border: 0.1rem solid #edeff1;
}

.feed-post-details .comment .profile-img img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.feed-post-details .comment .profile-name {
    font-size: 1rem;
    margin-right: 1rem;
}

.feed-post-details .comment-body {
    font-size: 1.2rem;
    padding: 1rem;
}

.feed-post-details .comment-footer {
    display: flex;
    width: 100%;
    gap: 1rem;
    font-size: 1.2rem;
    padding: 1rem;
}

.feed-post-details .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0.2rem 0.8rem;
    border-radius: 1rem;
    background: #f6f7f8;
    border: 0.1rem solid #edeff1;
    cursor: pointer;
}

.comment-footer .icons:hover {
    color: #5865f2;
}

.comment-footer .icons i {
    font-size: 1.2rem;
}

.about-alert,
.about-alert>p {
    background: #be1431;
    color: #fff !important;
}

.bar {
    width: 100%;
    height: 0.1rem;
    background: #edeff1;
    margin: 0.5rem 0;
}


/* Post Detail Page */


/* Post Profile Card */

.post-profile {
    position: relative;
}

.post-profile .top-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.post-profile .top-background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.post-profile .post-profile-header {
    display: flex;
    align-items: center;
    margin-top: 3.5rem;
}

.post-profile-header .profile-img {
    height: 4rem;
    min-height: 4rem;
    width: 4rem;
    min-width: 4rem;
    margin-right: 0.5rem;
}

.post-profile-header .profile-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 50%;
}

.post-profile-header .profile-name {
    font-weight: bold;
}

.post-profile-about {
    font-size: 1.3rem;
    margin: 1rem 0;
}

.post-profile-about span {
    color: #838485;
}

.post-profile-stats {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.post-profile-stats .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    background: #f6f7f8;
    border: 0.1rem solid #edeff1;
    cursor: pointer;
    font-size: 1rem;
}

.post-profile-stats .icons:hover {
    color: #5865f2;
}

.post-profile-stats .icons i {
    font-size: 1.4rem;
}

.fa-down-on {
    color: #ff1e00;
    font-weight: bold;
}

.fa-up-on {
    color: #37ab09;
    font-weight: bold;
}


/* Post Profile Card */

.reply-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}

.reply-section textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
}

.reply-buttons {
    display: flex;
    column-gap: 10px;
    margin-top: 10px;
}

.reply-buttons button {
    margin-left: 10px;
}

.postViewURL {
    font-size: 1.2em !important;
    font-weight: lighter !important;
    text-decoration: none;
}