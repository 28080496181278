/*   Common Styles used throughout the App */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,300&display=swap");
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    outline: none;
    border: none;
    text-decoration: none;
    color: inherit;
}

html {
    font-size: 62.5%;
}

body {
    min-height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    background: #dae0e6;
    font-size: 1.4rem;
    color: #222;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-bottom: 0;
    margin-top: 0;
}

ol {
    padding-left: 0;
}

.btn:hover {
    color: #fff;
}


/* #F6F7F8 */


/* Container - xtra small */

.container-xs {
    margin-left: auto;
    margin-right: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}


/* Genre */

.genre {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    background: #000;
    display: inline-block;
    border-radius: 5rem;
    color: #fff;
}

.genre-1 {
    background: #ffb02e;
}

.genre-2 {
    background: #55c813;
}

.genre-3 {
    background: #c81c13;
}

.genre-4 {
    background: #136ac8;
}

.genre-5 {
    background: #000;
}


/* Genre */


/* Buttons */

.btn {
    padding: 0.8rem 1.5rem;
    border-radius: 2rem;
    font-size: 1.2rem;
    cursor: pointer;
}

.btn:disabled {
    opacity: 0.7;
}

.btn-1 {
    background: #5865f2;
    color: #fff;
    min-width: 7rem;
}

.btn-2 {
    background: transparent;
    color: #222;
    border: 0.1rem solid #ccc;
}

.btn-2:hover {
    color: #222;
}

.btn-3 {
    padding: 0;
    height: 4rem;
    width: 4rem;
    min-height: 4rem;
    min-width: 4rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 0.1rem solid #ccc;
    background: #5865f2;
}

.btn-3.xs {
    height: 2rem;
    width: 2rem;
    min-height: 2rem;
    min-width: 2rem;
    background: #fff;
    color: #222;
    font-size: 1rem;
}

.post-footer .edit-icon img {
    width: 25px;
}

.edit-icon a img {
    width: 25px;
}

.post-footer .delete-icon img {
    width: 21px;
    margin-left: -15px;
}

.delete-icon img {
    width: 21px;
    cursor: pointer;
}

.edit-icon img {
    width: 25px;
    cursor: pointer;
}

/* Buttons */


/* Form Feilds */

.f-control.select-control {
    display: flex;
    align-items: center;
}

.f-control.select-control label {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
}

.f-control.select-control select {
    padding: 0.8rem 1rem;
    border: 0.1rem solid #edeff1;
    border-radius: 1rem;
    font-size: 1.2rem;
    background: #f6f7f8;
}

.f-control .border {
    position: absolute;
    width: 0%;
    height: 0.1rem;
    bottom: 0;
    background: #5865f2;
    transition: 1s ease;
}

.f-control label {
    position: absolute;
    top: 2rem;
    left: 1rem;
    transform: translateY(-50%);
}

.f-control input {
    width: 100%;
    padding: 1rem 2rem;
    border-bottom: 0.1rem solid #ccc;
    border-radius: 0.2rem;
    padding-left: 3.5rem;
    font-size: 1.2rem;
}

.f-control textarea {
    width: 100%;
    height: 10rem;
    resize: none;
    padding: 1rem 2rem;
    border-bottom: 0.1rem solid #ccc;
    border-radius: 0.2rem;
    padding-left: 3.5rem;
    font-size: 1.2rem;
}

.f-control input:focus+.border {
    width: 100%;
}

.f-control input:focus {
    border-bottom: 0.1rem solid #5865f2;
}

.f-control textarea:focus+.border {
    width: 100%;
}


/* Edit Profile Table */

.edit-table {
    width: 100%;
    overflow-x: auto;
}

.edit-table th,
.edit-table td {
    padding: 1rem;
    font-size: 12px;
}

.edit-table .genre {
    display: inline-block;
    min-width: 8rem;
}

@media (min-width: 320px) {
    .search-bar {
        width: 50%;
    }
    .modal-content {
        margin: 10rem auto;
        padding: 1rem;
        max-width: 90%;
    }
    .close-post {
        display: none;
    }
    main.post-page {
        margin-top: 10rem !important;
    }
}

@media (max-width: 480px) {
    html {
        font-size: 50%;
    }
    .modal-content {
        margin: 10rem auto;
        padding: 1.5rem;
        max-width: 90%;
    }
    .post-footer {
        flex-wrap: wrap;
    }
    .post-footer .report-icon {
        margin-left: 0 !important;
    }
    .post-footer .icons span {
        /* display: none; */
    }
    .icons span {
        /* display: none; */
    }
    .report-icon i {
        margin-right: 0 !important;
    }
    .report-icon span {
        display: none;
    }
    .close-post {
        display: none;
    }
}

@media (min-width: 576px) {
    .container-xs {
        width: 100%;
    }
    .close-post {
        display: none;
    }
}

@media (min-width: 768px) {
    .container-xs {
        width: 100%;
    }
    .modal-content {
        margin: 10rem auto;
        padding: 2rem;
        max-width: 90%;
    }
    .close-post {
        display: flex;
    }
    main.post-page {
        margin-top: 10rem !important;
    }
}

@media (min-width: 992px) {
    .container-xs {
        width: 90rem;
    }
}

@media (min-width: 1200px) {
    .container-xs {
        width: 100rem;
    }
}

.askDeteriora {
    background: #ffb02e;
    color: #000000;
}

.worldNews {
    background: #458cc2;
    color: #ffffff;
}

.funny {
    background: #e31dff;
    color: #ffffff;
}

.pics {
    background: #22aa46;
    color: #ffffff;
}

.science {
    background: #ff0000;
    color: #ffffff;
}

.movies {
    background: #9321a7;
    color: #ffffff;
}

.gaming {
    background: #00bfff;
    color: #000000;
}

.technology {
    background: #ff4500;
    color: #ffffff;
}

.sports {
    background: #3aaae0;
    color: #000000;
}

.music {
    background: #8a2be2;
    color: #ffffff;
}

.books {
    background: #ffd700;
    color: #000000;
}

.iAmA {
    background: #9e6226;
    color: #ffffff;
}

.history {
    background: #228b22;
    color: #ffffff;
}

.food {
    background: #af3773;
    color: #ffffff;
}

.doItYourself {
    background: #22547d;
    color: #ffffff;
}

.space {
    background: #03eaee;
    color: #000000;
}

.gadgets {
    background: #7b2691;
    color: #ffffff;
}

.programming {
    background: #a84c0a;
    color: #ffffff;
}

.philosophy {
    background: #32cac2;
    color: #000000;
}

.television {
    background: #9932cc;
    color: #ffffff;
}

.travel {
    background: #e4492d;
    color: #ffffff;
}

.documentaries {
    background: #2e8b57;
    color: #ffffff;
}

.fitness {
    background: #ff1493;
    color: #ffffff;
}

.earthPorn {
    background: #2951ca;
    color: #ffffff;
}

.videos {
    background: #db7093;
    color: #ffffff;
}

.news {
    background: #709ef4;
    color: #000000;
}

.cryptoCurrency {
    background: #69deda;
    color: #000000;
}

.jokes {
    background: #c71585;
    color: #ffffff;
}

.art {
    background: #5f9ea0;
    color: #000000;
}

.bitcoin {
    background: #ffb02e;
    color: #000000;
}

.f-control.error input {
    border-color: red;
}

.error-text {
    color: red;
    font-size: 12px;
    margin-top: 4px;
}


/* Select Menu */

.create-post select {
    width: 100%;
    /* Take up as much space as it can */
    padding: 10px 15px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    background: #f4f4f4;
    /* Light grey background */
    appearance: none;
    /* Removes default styling */
    -webkit-appearance: none;
    /* Removes default styling for Webkit browsers */
    -moz-appearance: none;
    /* Removes default styling for Mozilla browsers */
    cursor: pointer;
    /* Hand cursor for the dropdown */
    font-size: 16px;
}

.create-post select:focus {
    outline: none;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    /* Shadow to indicate focus */
}


/* Custom dropdown arrow */

.create-post select::-ms-expand {
    display: none;
    /* For IE and Edge */
}

.create-post select::after {
    content: "\25BC";
    /* Down arrow symbol */
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    /* Arrow shouldn't be clickable */
}


/* Option styling - you can adjust this based on the previous styles provided */

.create-post select option {
    padding: 8px 10px;
    background-color: #f4f4f4;
    color: #333;
}

.create-post select option:hover {
    background-color: #e0e0e0;
}

.error-text-box {
    background-color: #9b2602 !important;
    color: #fff !important;
}

.f-control input[type="checkbox"] {
    width: auto;
    /* Resetting width for checkbox */
    padding: 0;
    /* Resetting padding for checkbox */
    border: none;
    /* Resetting border for checkbox */
    border-bottom: none;
    /* Resetting border-bottom for checkbox */
    border-radius: 0;
    /* Resetting border-radius for checkbox */
    font-size: inherit;
    /* Resetting font-size for checkbox */
    margin-right: 0.5rem;
    /* Space between checkbox and its label */
}